import { CarouselProps } from "~/utils/carouselProps"
import { Pagination } from "swiper/modules"

export const carouselOptions: CarouselProps = {
  slidesPerView: "auto",
  spaceBetween: 16,
  breakpoints: {
    1024: {
      spaceBetween: 24,
      slidesPerView: 3
    }
  },
  grabCursor: true,
  pagination: {
    enabled: true
  },
  navigation: true,
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30"
}
