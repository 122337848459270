<script setup lang="ts">
import type { ComponentBrandBannerProps } from "./ComponentBrandBanner.props"
import { mapProducts } from "~/utils/mapProducts"
const props = defineProps<ComponentBrandBannerProps>()

let brandBanner: ComponentBrandBannerProps = {
  ...props
}
const { $cl } = useNuxtApp()
const dataProducts = await productsPrice($cl, props.cards)
brandBanner.cards = mapProducts(dataProducts, null, "CONTENTFUL")
</script>

<template>
  <div class="cms-brand-banner">
    <NuxtLazyHydrate when-visible>
      <ProductBlocksBrandBlockBig
        v-if="brandBanner && brandBanner.cards.length > 0"
        v-bind="brandBanner"
      />
    </NuxtLazyHydrate>
  </div>
</template>
